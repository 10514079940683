:root {
  /* Grid margins */
  --grid-margins-xxs: 16px;
  --grid-margins-xs: 16px;
  --grid-margins-sm: 24px;
  --grid-margins-md: 24px;
  --grid-margins-lg: 40px;

  /* Content width sizes */
  --content-width-text-max: 564px;
  --content-width-sm: 648px;
  --content-width-lg: 1320px;

  /* Site breakpoints */
  --breakpoint-xxs: 375px;
  --breakpoint-xs: 480px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1148px;
  --breakpoint-xlg: 1400px;

  /* Spacings for margin/padding */
  --base-spacing: 8px;
  --spacing-05: calc(0.5 * var(--base-spacing)); /* 4px */
  --spacing-1: calc(1 * var(--base-spacing)); /* 8px */
  --spacing-15: calc(1.5 * var(--base-spacing)); /* 12px */
  --spacing-2: calc(2 * var(--base-spacing)); /* 16px */
  --spacing-25: calc(2.5 * var(--base-spacing)); /* 20px */
  --spacing-3: calc(3 * var(--base-spacing)); /* 24px */
  --spacing-4: calc(4 * var(--base-spacing)); /* 32px */
  --spacing-5: calc(5 * var(--base-spacing)); /* 40px */
  --spacing-6: calc(6 * var(--base-spacing)); /* 48px */
  --spacing-7: calc(7 * var(--base-spacing)); /* 56px */
  --spacing-8: calc(8 * var(--base-spacing)); /* 64px */
  --spacing-9: calc(9 * var(--base-spacing)); /* 72px */
  --spacing-10: calc(10 * var(--base-spacing)); /* 80px */

  /* Colors */
  --color-blue: #004496;
  --color-blue-active: #044088;
  --color-blue-hover: #02418c;
  --color-brown: #673600;
  --color-dark-gray1: #767676;
  --color-dark-gray1-active: #efefef;
  --color-dark-gray1-hover: #f5f5f5;
  --color-dark-gray2: #c4c4c4;
  --color-forest: #005112;
  --color-gray1: #dcdcde;
  --color-gray2: #eeeef0;
  --color-gray3: #f9f9fa;
  --color-hallon1: #630132;
  --color-hallon1-active: #F1E3E8;
  --color-hallon1-hover: #F6ECF0;
  --color-hallon2: #86004b;
  --color-hallon3: #ff6df0;
  --color-hallon4: #ff98f5;
  --color-hallon4-active: #e489db;
  --color-hallon4-hover: #ed8ee4;
  --color-hallon5: #ffc2f9;
  --color-hallon6: #ffecfd;
  --color-hallon6-hover: #eddceb;
  --color-hallon6-active: #e4d3e2;
  --color-lemon1: #fefc00;
  --color-lemon2: #fffb8b;
  --color-lemon3: #fffdc3;
  --color-mine-shaft: #1f1f1f;
  --color-mine-shaft-active: #e4e4e4;
  --color-mine-shaft-hover: #ededed;
  --color-nyanza: #e4f5e4;
  --color-nyanza-hover: #d4e4d4;
  --color-nyanza-active: #ccdbcc;
  --color-ocean: #00b2ff;
  --color-rose: #ffeae7;
  --color-sand: #f6f1dc;
  --color-sky: #e4f2ff;
  --color-white: #ffffff;
  --color-white-active: rgba(255, 255, 255, 0.12);
  --color-white-hover: rgba(255, 255, 255, 0.08);
  --color-transparent: transparent;

  /* Border radius */
  --border-radius-lg: 48px; /* Old XXLarge */
  --border-radius-md: 24px; /* Old Xlarge & Large */
  --border-radius-sm: 12px; /* Old Medium, Small */
  --border-radius-xs: 4px; /* Old XSmall */

  /* Font weight */
  --font-weight-semi-bold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;

  /* Shadows */
  --shadow-color: rgba(31, 31, 31, 0.1); /* mineshaft 10% */
  --shadow-default: 0 2px 5px 0 var(--shadow-color);
  --shadow-hover: 0 3px 6px 0 var(--shadow-color);
  --shadow-menu: 0 12px 25px 0 var(--shadow-color);
  --shadow-tabbar: 0 -2px 5px 0 var(--shadow-color);
  --shadow-noTopShadow: 0 2px 5px -6px var(--shadow-color);
}
